import React from "react";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import AnchorLink from "react-anchor-link-smooth-scroll";
// import bgImg from "../images/404-bg.png";
import {
  darkBlue,
  mainWhite,
  Subtitle,
  InnerWrapper,
  screen,
} from "../components/common/variables";

const Wrapper = styled.div`
  min-height: 70vh;
  background-color: ${mainWhite};
  padding-top: 30px;
  @media ${screen.medium} {
    padding-top: 160px;
  }

  p {
    color: ${darkBlue};
    margin-top: 30px;
    font-size: 1.1rem;
    @media ${screen.small} {
      font-size: 1.1rem;
    }
    @media ${screen.large} {
      font-size: 1.2rem;
    }
    @media ${screen.xLarge} {
      font-size: 1.3rem;
    }
    @media ${screen.xXLarge} {
      font-size: 1.4rem;
    }

    a {
      color: ${darkBlue};
      font-weight: 700;
    }
  }
`;

const RegistrationConfirmationPage = () => {
  return (
    <Layout>
      <SEO title="Registration Confirmation | Global Adviser Alpha" />
      <Wrapper>
        <div>
          <InnerWrapper className="inner-wrapper">
            <Subtitle className="title" dark>
              Thank you
            </Subtitle>
            <p>
              Thanks for registering for the Client-Centric Advice Program
              (Enterprise).
              <br />
              We have sent an email with a confirmation link to your email
              address. <br />
              In order to complete registering your interest, please click on
              the confirmation link.
            </p>

            <p>
              If you need any assistance, please don’t hesitate to{" "}
              <AnchorLink href="#footer">contact us</AnchorLink>.
            </p>
          </InnerWrapper>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default RegistrationConfirmationPage;
